import { useEffect, useRef, useState } from "react";
import logo from "./logo3.png";
import axios from 'axios';
import useRazorpay from "react-razorpay";
import './style.css';
function App() {

    const [GetTeamName, SetTeamName] = useState();
    const [GetLeaderName, SetLeaderName] = useState();
    const [GetAYearOfStudy, SetAYearOfStudy] = useState();
    const [GetEmail, SetEmail] = useState();
    const [GetPhoneNo, SetPhoneNo] = useState();
    const [GetBMemberName, SetBMemberName] = useState("NA");
    const [GetBYearOfStudy, SetBYearOfStudy] = useState("NA");
    const [GetCMemberName, SetCMemberName] = useState("NA");
    const [GetCYearOfStudy, SetCYearOfStudy] = useState("NA");
    const [GetProblemState, SetProblemState] = useState();
    const [GetQuizAttempt, SetQuizAttempt] = useState();

    const [Razorpay] = useRazorpay();

    const HandlePaymentCallBackSuccess = (razorpay_payment_id, razorpay_order_id, razorpay_signature, amount) => {
        const formdata = new FormData();
        formdata.append("razorpay_payment_id", razorpay_payment_id);
        formdata.append("razorpay_order_id", razorpay_order_id);
        formdata.append("razorpay_signature", razorpay_signature);
        formdata.append("amount", amount);
        formdata.append("email", GetEmail);
        axios.post("https://vortexictmumbai.com/sridpb/PayMentStateUp.php", formdata).then(response => {
          
            console.log(response.data);

            alert("Payment Done Successfully. Confirmation Mail will be Sent to your Registered Mail ID");

        }).catch(err => {
          alert("Contact Admin");
            console.log(err);
        })
    }




    const handlePayment = async (orderid, amount, currency, email) => {


        const options = {
            key: "rzp_live_gwEAT74AB6AMML", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: currency,
            name: GetLeaderName,
            description: "Vortex 11.0x Registration",
            image: "https://sridp.vortexictmumbai.com/logo512.png",
            order_id: orderid.toString(), //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                HandlePaymentCallBackSuccess(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature, amount, email);
                console.log(response);
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
            },
            prefill: {
                name: GetLeaderName,
                email: GetEmail,
                contact: "+91 " + GetPhoneNo,
            },
            notes: {
                address: GetEmail,
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });




    };




    const HandleFormSubmit = (e) => {
        e.preventDefault();
        if (Boolean(GetTeamName) && Boolean(GetLeaderName) && Boolean(GetAYearOfStudy) && Boolean(GetPhoneNo) && Boolean(GetEmail) && Boolean(GetBMemberName) && Boolean(GetBYearOfStudy) && Boolean(GetCMemberName) && Boolean(GetCYearOfStudy) && Boolean(GetProblemState) && Boolean(GetQuizAttempt)) {
            const formdata = new FormData();

            /*
// Got Variables
$teamname = $_POST["teamname"];
$leadername = $_POST["leadername"];
$emailid = $_POST["email"];
$phone = $_POST["phone"];
$yearofstudy = $_POST["yearofstudy"];
$mname2 = $_POST["mname2"];
$yearofstudy2 = $_POST["yearofstudy2"];
$mname3 = $_POST['mname3'];
$yearofstudy3 = $_POST['yearofstudy3'];
$problemstatement = $_POST['problemstatement'];
$quizattempt = $_POST['quizattempt'];
$orderIdValue = $orderdetails['id'];
$orderReciept = $orderdetails['receipt'];
$orderAmount = $orderdetails['amount'];
$orderCurrency = $orderdetails['currency'];
            */
            formdata.append("teamname", GetTeamName);
            formdata.append("leadername", GetLeaderName);
            formdata.append("yearofstudy", GetAYearOfStudy);
            formdata.append("email", GetEmail);
            formdata.append("phone", GetPhoneNo);
            formdata.append("mname2", GetBMemberName);
            formdata.append("yearofstudy2", GetBYearOfStudy);
            formdata.append("mname3", GetCMemberName);
            formdata.append("yearofstudy3", GetCYearOfStudy);
            formdata.append("problemstatement", GetProblemState);
            formdata.append("quizattempt", GetQuizAttempt);

            axios.post("https://vortexictmumbai.com/sridpb/PayB.php", formdata).then(response => {
                // window.location.replace(response.data);
                console.log(response.data[0])
                if (response.data[0].status === true) {
                    const ArrayData = response.data[0].message ? response.data[0].message[0] : null;
                    if (Boolean(ArrayData)) {
                        handlePayment(ArrayData['id'], ArrayData['amount'], ArrayData['currency'], GetEmail)
                    }


                } else {
                    alert("Something Went Wrong");
                }


            }).catch(err => {
                console.log(err);
            })

        } else {
            alert("Please Fill All Fields");
        }
    }


    return (
        <>

            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container">
                    <a className="navbar-brand me-2" href="https://vortexictmumbai.com/">
                        <img
                            src={logo}
                            height={50}
                            alt="MDB Logo"
                            loading="lazy"
                            style={{ marginTop: "-1px" }}
                        />
                    </a>
                    <button
                        data-mdb-collapse-init=""
                        className="navbar-toggler"
                        type="button"
                        data-mdb-target="#navbarButtonsExample"
                        aria-controls="navbarButtonsExample"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="fas fa-bars" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarButtonsExample">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" href="#">
                                    Welcome to VORTEX 11.0, India's largest ChemFest!
                                </a>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center">
                            <a href="https://login.vortexictmumbai.com/">
                                <button
                                    data-mdb-ripple-init=""
                                    type="button"
                                    className="btn btn-primary me-3"
                                >
                                    Login
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
            {/* Navbar */}
            <br />
            <div className='container'>
                <div className="heading"><h3>SR. IDP EVENT REGISTRATION.</h3>
                </div>

                <div className="heading">
                    <p>Make a team of maximum 3 members. & Last Date to apply: 31-Dec-2023, 11:59PM  IST.</p>
                </div>

                <form className="row g-3" onSubmit={HandleFormSubmit}>
                    <div className="col-md-12">
                        <label htmlFor="inputName" className="form-label">
                            Team Name
                        </label>
                        <input type="text" className="form-control" onChange={(e) => {
                            SetTeamName(e.target.value);
                        }} />

                    </div>

                    <div className="col-md-6">
                        <label className="form-label">
                            Name of the Team Leader
                        </label>
                        <input type="text" className="form-control" onChange={(e) => {
                            SetLeaderName(e.target.value);
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inputCName" className="form-label">
                            Course and Year of Study
                        </label>
                        <input type="text" className="form-control" id="inputCName" onChange={(e) => {
                            SetAYearOfStudy(e.target.value);
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">
                            Email
                        </label>
                        <input type="email" className="form-control" placeholder="Team Leader" onChange={(e) => {
                            SetEmail(e.target.value);
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">
                            WhatsApp number
                        </label>
                        <input type="tel" className="form-control" onChange={(e) => {
                            SetPhoneNo(e.target.value);
                        }} />
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">
                            Name of team member – 2
                        </label>
                        <input type="text" className="form-control" onChange={(e) => {
                            SetBMemberName(e.target.value);
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inputCName" className="form-label" >
                            Course and Year of Study
                        </label>
                        <input type="text" className="form-control" placeholder="For Member – 2" onChange={(e) => {
                            SetBYearOfStudy(e.target.value);
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">
                            Name of team member – 3
                        </label>
                        <input type="text" className="form-control" onChange={(e) => {
                            SetCMemberName(e.target.value);
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">
                            Course and Year of Study
                        </label>
                        <input type="text" className="form-control" placeholder="For Member – 3" onChange={(e) => {
                            SetCYearOfStudy(e.target.value);
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">
                            No. of problem statements to be attempted:
                        </label>
                        <select className="form-select" onChange={(e) => {
                            SetProblemState(e.target.value)
                        }}>
                            <option>Choose...</option>
                            <option value="one">One</option>
                            <option value="two">Two</option>
                            <option value="many">Unlimited (Three or more) </option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inputGender" className="form-label">
                            Did you attempt the Quiz
                        </label>
                        <select className="form-select" onChange={(e) => {
                            SetQuizAttempt(e.target.value)
                        }}>
                            <option>Choose...</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    <div className="col-8 new">
                        <button
                            
                            type="submit"
                            className="btn btn-primary btn-block"
                        >
                            Register
                        </button>
                    </div>

                </form>
                <br />

            </div>
        </>

    );
}

export default App;